import { React } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const RequireAuth = () => {
  const { session } = useSelector((state) => state.auth)

  return !session?.token ? <Navigate to="/login" /> : <Outlet />
}

export default RequireAuth
