import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    show: true,
  },
  reducers: {
    changeState: (state, action) => {
      state.show = action.payload
    },
  },
})

export const { changeState } = sidebarSlice.actions
export default sidebarSlice.reducer
