import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "/api";

const initialState = {
  reportId: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

// Login user
export const getReportId = createAsyncThunk(
  "admin/getReportId",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(API_URL + "/menu/current-menu");

      return data.body;
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReportId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReportId.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.reportId = null;
      } else {
        state.reportId = action.payload[0].id;
      }

      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(getReportId.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const {} = adminSlice.actions;
export default adminSlice.reducer;
