import sidebar from "./features/sidebar";
import auth from "./features/auth";
import admin from "./features/adminSlice";

import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./features/apiSlice";

const store = configureStore({
  reducer: { sidebar, auth, admin, [apiSlice.reducerPath]: apiSlice.reducer },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware);
  },
  devTools: true,
});

export default store;
